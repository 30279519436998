import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import {
  ActivityLogDialogComponent,
  BaseComponent,
  CaseEdit,
  DateUtil,
  FennecSnackbarService,
  Logger,
  MICaseService,
  SingleChoiceDialogComponent
} from 'xf-common';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CaseEditComponent } from '../case-edit/case-edit.component';
import { KeycloakService } from 'keycloak-angular';
import { PrimaryClientEditDialogComponent } from '../primary-client-edit-dialog/primary-client-edit-dialog.component';
import {
  MiCasePlanEditDialogComponent
} from '@app/health-care-plan/mi-case-plan-edit-dialog/mi-case-plan-edit-dialog.component';
import { ActivatedRoute } from "@angular/router";
import { StatusTrackManualAssignComponent } from '@app/status-track/status-track-manual-assign/status-track-manual-assign.component';
import { takeUntil } from 'rxjs';
import { Ub04Service } from 'xf-common';
import { ActivityLogDialogConfig, StatusTrackDialogConfig } from 'projects/xf-common/src/public-api';
import { StatusTrackDialogComponent } from '../../status-track/status-track-dialog/status-track-dialog.component';
import { SendEmailDialogComponent } from "../../email/send-email-dialog/send-email-dialog.component";

@Component({
  selector: 'app-mi-case-general',
  templateUrl: './mi-case-general.component.html',
  styleUrls: ['./mi-case-general.component.scss']
})
export class MiCaseGeneralComponent extends BaseComponent implements AfterViewInit {

  log: Logger = new Logger("MICaseGeneralComponent");

  @Output()
  openEditDialog = new EventEmitter<void>();

  @Input()
  miCaseId = "-1";

  miCase: any = null;
  typeList: any[] = [];
  linesOfBusinessList: any[] = [];
  customerPriorityList: any[] = [];
  typeDisplay: string = "";
  lobDisplay: string = "";
  priorityDisplay: string = "";
  estimatedAmountDue: string = "N/A";

  isUserAdmin = false;
  isUserCaseClientUpdate = false;
  isUserCaseHealthCarePlanUpdate = false;
  activityLogAccess = false;

  constructor(
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    private miCaseService: MICaseService,
    private keycloakService: KeycloakService,
    private ub04Service: Ub04Service,
    private route: ActivatedRoute
  )
  {
    super();
    this.getCaseTypes();
    this.getLinesOfBusiness();
    this.getCustomerPriorities();

    const roles = this.keycloakService.getUserRoles();
    this.isUserAdmin = roles.includes("ADMIN");
    this.isUserCaseClientUpdate = roles.includes("CASE_PRIMARY_CLIENT_UPDATE");
    this.isUserCaseHealthCarePlanUpdate = roles.includes('CASE_HEALTH_CARE_PLAN_UPDATE');
    this.activityLogAccess = this.isUserAdmin || roles.includes("CASE_ACTIVITY_LOG");

    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngAfterViewInit(): void {
    this.getMiCaseInfo();
    this.getUB04PrimaryPayer();
    this.miCaseService.refreshCaseInfo$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => this.getMiCaseInfo());
  }

  handleEditCustomerStatus(miCase: any, action: string) {
    let infoLine1 = "Case: " + miCase.id + " Main Status: ";
    if (action === "COMPLETE") {
      infoLine1 += " COMPLETE";
    } else if (action === "CANCEL") {
      infoLine1 += " CANCELLED";
    } else {
      infoLine1 += this.miCase.statusTrackStatus;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCase: miCase,
      statusTrackDomain: "MI_CASE",
      statusTrackElement: "MI_CASE_CF_STATUS",
      elementLabel: "Customer Facing Status",
      initialStatusTrackStatus: miCase.customerFacingStatusCode,
      infoLine1: infoLine1
    }
    const dialogRef = this.matDialog.open(StatusTrackManualAssignComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getMiCaseInfo();
      }
    });
  }

  handleOpenEditDialog(miCase: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCase: miCase
    }
    const dialogRef = this.matDialog.open(CaseEditComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.updateCase(result.case);
      }
    });
  }

  openActivityLog() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";

    const aldConfig = new ActivityLogDialogConfig();
    aldConfig.activityLogEntity = "CASE";
    aldConfig.relatedId = parseInt(this.miCaseId);
    matDialogConfig.data = aldConfig;
    const dialogRef = this.matDialog.open(ActivityLogDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

  openStatusTrackLog() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "80vw";

    const stdConfig = new StatusTrackDialogConfig();
    stdConfig.statusTrackDomain = "MI_CASE";
    stdConfig.relatedId = parseInt(this.miCaseId);
    matDialogConfig.data = stdConfig;
    const dialogRef = this.matDialog.open(StatusTrackDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }  

  updateCase(miCase: any) {
    let updatedCasePacket = Object.assign(new CaseEdit(), miCase);

    this.performXFRequest({
      requestDescription: "Update Case Info",
      requestFn: this.miCaseService.updateCaseDialog,
      fnParams: [updatedCasePacket],
      onSuccess: (_data) => {
        this.snack.showSuccessSnack("Case updated successfully");
        this.getMiCaseInfo();
        this.miCaseService.emitRefreshCaseInfo();
      },
      onError: (err) => {
        this.snack.showErrorSnack(err);
      }
    });
  }

  getMiCaseInfo() {
    if (this.miCaseId === null || this.miCaseId === undefined || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Info",
      requestFn: this.miCaseService.getMICaseInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        this.miCase = null;
        if (response.data !== null && response.data !== undefined) {
          this.miCase = response.data;
          this.calculateDisplayValues();
          // if (this.miCase?.caseReviewDueDate != null) {
          //   this.miCase.caseReviewDueDate = DateUtil.fennecDBStringToInputDateString(this.miCase?.caseReviewDueDate);
          // }
          // if (this.miCase?.ibRequestedDate != null) {
          //   this.miCase.ibRequestedDate = DateUtil.fennecDBStringToInputDateString(this.miCase?.ibRequestedDate);
          // }
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  // Used to display Total Billed Amount from UB04 if it exists
  getUB04PrimaryPayer = () => {
    if (this.miCaseId === null || this.miCaseId === undefined || this.miCaseId === "-1") {
      return;
    }
    // this.performXFRequest({
    //   requestDescription: "GET UB04 Primary Payer Info",
    //   requestFn: this.ub04Service.getUb04PrimaryPayerByMICaseId,
    //   fnParams: [parseInt(this.miCaseId)],
    //   onResponse: response => {
    //     if (response.data != null) {
    //       this.estimatedAmountDue = response.data.estimatedAmountDue;
    //     }
    //   },
    //   onError: errString => {
    //     super.showErrorSnack(errString);
    //   }
    // });

    this.ub04Service.getUb04PrimaryPayerByMICaseId(parseInt(this.miCaseId)).subscribe((serverResponse) => {
      if (serverResponse.hasErrors) {
        this.snack.showErrorSnack("Error Getting UB04 Provider: " + serverResponse.consolidatedErrorMessage);
      } else{
        if (serverResponse.data != null) {
          this.estimatedAmountDue = serverResponse.data.estimatedAmountDue;
        }
      }
    });
  }

  confirmCloseDialog(action: string) {
    let message = "Are you sure you want to mark Case ID: " + this.miCaseId;
    if (action === "COMPLETE") {
      message += " as complete?";
    } else {
      message += " as cancelled?";
    }
    const id = parseInt(this.miCaseId);

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: message
    }
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse.confirm) {
        if (action === "COMPLETE") {
        this.miCaseService.completeMICase(id).subscribe((serverResponse) => {          
          if (serverResponse.hasErrors) {
            this.snack.showErrorSnack("Error completing Case: " + serverResponse.consolidatedErrorMessage);
          } else{
            this.snack.showSuccessSnack("Case marked complete!");
            this.getMiCaseInfo();
            this.handleEditCustomerStatus(this.miCase, action);
          }
        });
      } else {
        this.miCaseService.cancelMICase(id).subscribe((serverResponse) => {          
          if (serverResponse.hasErrors) {
            this.snack.showErrorSnack("Error cancelling Case: " + serverResponse.consolidatedErrorMessage);
          } else{
            this.snack.showSuccessSnack("Case cancelled");
            this.getMiCaseInfo();
            this.handleEditCustomerStatus(this.miCase, action);
          }
        });        
      }
      }
    });
  }

  getCaseTypes() {
    this.performXFRequest({
      requestDescription: "Get Case Types",
      requestFn: this.miCaseService.getCaseTypes,
      fnParams: [],
      onSuccess: (data) => {
        this.typeList = data;
        this.calculateDisplayValues();
      }
    });
  }

  getLinesOfBusiness() {
    this.performXFRequest({
      requestDescription: "Get Lines of Business",
      requestFn: this.miCaseService.getLinesOfBusiness,
      fnParams: [],
      onSuccess: (data) => {
        this.linesOfBusinessList = data;
        this.calculateDisplayValues();
      }
    });
  }

  getCustomerPriorities() {
    this.performXFRequest({
      requestDescription: "Get Customer Priorities",
      requestFn: this.miCaseService.getCustomerPriorities,
      fnParams: [],
      onSuccess: (data) => {
        this.customerPriorityList = data;
        this.calculateDisplayValues();
      }
    });
  }

  calculateDisplayValues() {
    if(!this.miCase) {
      return;
    }

    const priority = this.miCase.customerPriority;
    if (this.customerPriorityList && priority != null && priority != "") {
      const enumValue = this.customerPriorityList.find(prio => priority.toLowerCase() === prio.value.toLowerCase());
      if (enumValue != null) {
        this.priorityDisplay = enumValue.label;
      }
    }
    const lob = this.miCase.lineOfBusiness;
    if (this.linesOfBusinessList && lob != null && lob != "") {
      const enumValue = this.linesOfBusinessList.find(enumVal => lob.toLowerCase() === enumVal.value.toLowerCase());
      if (enumValue != null) {
        this.lobDisplay = enumValue.label;
      }
    }
    const type = this.miCase.type;
    if (this.typeList && type != null && type != "") {
      const enumValue = this.typeList.find(enumVal => type.toLowerCase() === enumVal.value.toLowerCase());
      if (enumValue != null) {
        this.typeDisplay = enumValue.label;
      }
    }
  }

  openPrimaryClientEditDialog() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      currentCompanyId: this.miCase.primaryClientId
    }
    const dialogRef = this.matDialog.open(PrimaryClientEditDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if(dialogResponse.confirm) {

        const dto = {
          miCaseId: this.miCaseId,
          companyId: dialogResponse.companyId,
        }

        this.performXFRequest({
          requestDescription: "Update Primary Client",
          requestFn: this.miCaseService.updateMICasePrimaryClient,
          fnParams: [dto],
          onSuccess: response => {
            super.showSuccessSnack("Primary Client Updated");
            // this.getMiCaseInfo();
            if (this.isUserCaseHealthCarePlanUpdate) {
              this.openHealthCarePlanEditDialog(dialogResponse.companyId);
            }
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        });
      }
    })
  }

  openHealthCarePlanEditDialog(clientId = null) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      primaryClientId: clientId ?? this.miCase.primaryClientId,
      currentPlanId: this.miCase.healthCarePlanId
    }
    // console.log(this.miCase.primaryClientId);
    const dialogRef = this.matDialog.open(MiCasePlanEditDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse.confirm) {
        const dto = {
          relatedId: this.miCaseId,
          healthCarePlanIds: [dialogResponse.healthCarePlanId],
        }

        this.performXFRequest({
          requestDescription: "Update Health Care Plan",
          requestFn: this.miCaseService.updateMICaseHealthCarePlan,
          fnParams: [dto],
          onSuccess: response => {
            super.showSuccessSnack("Health Care Plan Updated");
            this.getMiCaseInfo();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        });
      } else if (clientId != null) {
        // If Client ID param is not null, that means dialog was opened by primary client dialog
        // In that case, mi case needs to be updated here
        this.getMiCaseInfo();
      }
    });
  }

  openEmailDialog = () => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    const dialogRef = this.matDialog.open(SendEmailDialogComponent, matDialogConfig);
  }
}
