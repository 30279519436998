import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  BaseComponent,
  FennecSnackbarService,
  Logger,
  MaterialModule
} from 'xf-common';
import { EmailService } from '../email.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivityDiaryDialogComponent } from '../../activity-diary/activity-diary-dialog/activity-diary-dialog.component';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrl: './send-email-dialog.component.scss'
})
export class SendEmailDialogComponent extends BaseComponent {

  protected log: Logger = new Logger("SendEmailDialogComponent");

  formGroup: FormGroup;

  constructor(
    override snack: FennecSnackbarService,
    protected keycloakService: KeycloakService,
    protected emailService: EmailService,
    protected dialog: MatDialog,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivityDiaryDialogComponent>
  ) {
    super();
    this.formGroup = this.createFormGroup();
   }

   sendEmail = () => {
    const controls = this.formGroup.controls;
    const dto = {
      toName: controls["toName"].value,
      toAddress: controls["toAddress"].value,
      subject: controls["subject"].value,
      messageBody: controls["messageBody"].value,
    }
    this.performXFRequest({
      requestDescription: "Send Email",
      requestFn: this.emailService.sendEmail,
      fnParams: [dto],
      // onComplete: () => {
      //   this.isLoading = false;
      // },
      onSuccess: response => {
        super.showSuccessSnack("Email Sent!");
        this.close();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
   }

   createFormGroup = () => {
    return new FormGroup({
      toName: new FormControl("", Validators.required),
      subject: new FormControl("", Validators.required),
      toAddress: new FormControl("", Validators.required),
      messageBody: new FormControl("", Validators.required)
    })
   }

   close = () => {
    this.dialogRef.close();
   }
}
