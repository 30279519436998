import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { CommentListDialogComponent } from '@app/comments/comment-list-dialog/comment-list-dialog.component';
import {
  BaseComponent,
  CaseEdit,
  FennecSnackbarService,
  Logger,
  MICaseService,
  SingleChoiceDialogComponent,
  StatusTrackService
} from 'xf-common';
import { CreateCaseComponent } from '../case-create/case-create.component';
import { CaseEditComponent } from '../case-edit/case-edit.component';
import { KeycloakService } from 'keycloak-angular';
import { XfwfService } from '@app/xfwf/xfwf.service';
import { XfwfCreateManualComponent } from '@app/xfwf/xfwf-create-manual/xfwf-create-manual.component';
import { StatusTrackManualAssignComponent } from '@app/status-track/status-track-manual-assign/status-track-manual-assign.component';
import { SendEmailDialogComponent } from '../../email/send-email-dialog/send-email-dialog.component';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class CaseListComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Output()
  openEditDialog = new EventEmitter<void>();

  @Input()
  miCaseId?: string;
  log: Logger = new Logger("CaseListComponent");

  dataSource = new MatTableDataSource<any>();

  displayColumns = [
    'id', 'caseType', 'currentXFWFStageName', 'altexternalid', 'name',
    'clientName', 'assignee', 'createdBy', 'dueDate',
    'actions'
  ];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;

  idOrderBy: "ASC" | "DESC" | null = "DESC";
  dueDateOrderBy: "ASC" | "DESC" | null = null;

  queryParams = {
    miCaseId: this.miCaseId,
    altExternalId: "",
    clientClaimId: "",
    miCaseName: "",
    statusTrackStatus: "",
    customerFacingStatus: "",
    createdBy: "",
    primaryClientName: "",
    healthCarePlanName: "",
    idAscDesc: this.idOrderBy,
    dueDateAscDesc: this.dueDateOrderBy
  };

  // For XFWF Manual Task creation.
  // TODO: Maybe move this into the xfwf manual task creation dialog. That's the only reason
  // these lists are here. They are passed into that dialog when that dialog could simply do
  // this itself.
  taskTypes: any [] = [];
  taskStatuses: any [] = [];

  miCaseMainStatusTrackStatuses: any[] = [];
  customerFacingStatuses: any[] = [];

  defaultPageSize = 20;
  pageSizeOptions = [10, 20, 25, 50];

  admin : boolean = false;
  showCreateManualXFWFTaskButton: boolean = false;
  canReOpen: boolean = false;

  constructor(
    private caseService: MICaseService,
    private keycloakService: KeycloakService,
    public matDialog: MatDialog,
    private router: Router,
    protected snack: FennecSnackbarService,
    private statusTrackService: StatusTrackService,
    private xfwfService: XfwfService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getCases();
      });
    }
  }

  ngOnInit() {
    this.admin = this.keycloakService.isUserInRole("ADMIN");
    this.showCreateManualXFWFTaskButton =
      this.keycloakService.isUserInRole("XFWF_MANUAL_TASK_ENTRY") || this.admin;
    this.canReOpen = this.admin || this.keycloakService.isUserInRole("CASE_RE_OPEN");
    this.getCases();
    this.getMICaseMainStatusTrackStatuses();
    this.getCustomerFacingStatuses();
    this.getTaskTypes();
    this.getTaskStatuses();
  }

  navigateToMiCaseTaskList(miCase: any) {
    this.router.navigateByUrl(`/mi-case/${miCase.id}/task-list`);
  }

  // TODO: Maybe move this into the xfwf manual task creation dialog. That's the only reason
  // why this is here.
  getTaskTypes() {
    this.performXFRequest({
      requestDescription: "Get Task Types",
      requestFn: this.xfwfService.getTaskTypes,
      fnParams: [],
      onSuccess: data => {
        this.taskTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  // TODO: Maybe move this into the xfwf manual task creation dialog. That's the only reason
  // why this is here.
  getTaskStatuses() {
    this.performXFRequest({
      requestDescription: "Get Task Statuses",
      requestFn: this.xfwfService.getTaskStatuses,
      fnParams: [],
      onSuccess: data => {
        this.taskStatuses = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  openEditCaseDialog(miCase: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCase: miCase
    }
    const dialogRef = this.matDialog.open(CaseEditComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.updateCase(result.case.id, result.case.altExternalId, result.case.name);
        this.getCases();
      }
    });
  }

  updateCase(caseId: number, altExternalId: string, name: string) {
    const updatedCasePacket = new CaseEdit();
    updatedCasePacket.id = caseId;
    updatedCasePacket.altExternalId = altExternalId;
    updatedCasePacket.name = name;
    this.caseService.updateCaseDialog(updatedCasePacket).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showSuccessSnack("Case updated successfully");
        this.getCases();
      }
    });
  }

  createCase() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    const dialogRef = this.matDialog.open(CreateCaseComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getCases();
        this.router.navigateByUrl(`/mi-case/${result.id}`);
      }
    });
  }

  navigateToCase(id: number) {
    if (id) {

      // Warn the user if the case is in terminalStatus.
      let miCase = this.getCase(id);
      if (miCase !== null && miCase.terminalStatus) {
        const matDialogConfig = new MatDialogConfig();
        matDialogConfig.disableClose = true;
        matDialogConfig.height = "auto";
        matDialogConfig.width = "auto";
        matDialogConfig.data = {
          infoLine1: "Case Main Status is: " + miCase.statusTrackStatus,
          question: "Are you sure you wish to continue?"
        }
        const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
        dialogRef.afterClosed().subscribe((dialogResponse) => {
          if (dialogResponse.confirm) {
            this.router.navigateByUrl(`/mi-case/${id}`);
            return;
          }
        });
      } else {
        this.router.navigateByUrl(`/mi-case/${id}`);
      }
      
    }
  }

  getCase(id: number) {
    let miCase: any = null;
    this.dataSource.data.forEach(c => {
      if (c.id === id) {
        miCase = c;
      }
    });
    return miCase;
  }

  getCases() {
    this.queryParams.miCaseId = this.queryParams.miCaseId?.toString()?.trim() ?? "";
    this.queryParams.altExternalId = this.queryParams.altExternalId?.trim() ?? "";
    this.queryParams.miCaseName = this.queryParams.miCaseName?.trim() ?? "";
    this.queryParams.idAscDesc = this.idOrderBy;
    this.queryParams.dueDateAscDesc = this.dueDateOrderBy;
    this.queryParams.createdBy = this.queryParams.createdBy?.trim() ?? "";
    this.queryParams.primaryClientName = this.queryParams.primaryClientName?.trim() ?? "";
    this.queryParams.healthCarePlanName = this.queryParams.healthCarePlanName?.trim() ?? "";

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "GET All Cases",
      requestFn: this.caseService.getAllCases,
      fnParams: [this.queryParams, first, pageSize],
      onResponse: response => {
        this.dataSource.data = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  clearSearch() {
    this.queryParams.miCaseId = "";
    this.queryParams.altExternalId = "";
    this.queryParams.miCaseName = "";
    this.queryParams.statusTrackStatus = "";
    this.queryParams.customerFacingStatus = "";
    this.queryParams.createdBy = "";
    this.queryParams.primaryClientName = "";
    this.queryParams.healthCarePlanName = "";
    this.getCases();
  }

  searchMICases() {
    this.paginator?.firstPage();
    this.getCases();
  }

  confirmStatusActionDialog(miCase:any, statusTrackStatus: string) {
    let message = "Are you sure you want to mark Case ";
    if (miCase?.altExternalId) {
      message += "'" + miCase.altExternalId + "'";
    } else if(miCase?.name) {
      message += "'" + miCase?.name + "'";
    } else if(miCase?.id) {
      message += "Id " + miCase.id;
    }

    if (statusTrackStatus === "COMPLETE") {
      message += " as COMPLETE?";
    } else if (statusTrackStatus === "CANCELLED") {
      message += " as CANCELLED?";
    } else if (statusTrackStatus === "IN-PROGRESS") {
      message += " as IN PROGRESS?";
    } else {
      return;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: message
    }
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if(dialogResponse.confirm) {
        if (statusTrackStatus === "COMPLETE") {
          this.caseService.completeMICase(miCase.id).subscribe((serverResponse) => {
            if (serverResponse.hasErrors) {
              this.snack.showErrorSnack("Error Completing Case: " + serverResponse.consolidatedErrorMessage);
            } else{
              this.snack.showSuccessSnack("Case marked complete!");
              this.editCustomerFacingStatusDialog(miCase, statusTrackStatus);
            }
          });
        } else if (statusTrackStatus === "CANCELLED") {        
          this.caseService.cancelMICase(miCase.id).subscribe((serverResponse) => {
            if (serverResponse.hasErrors) {
              this.snack.showErrorSnack("Error Cancelling Case: " + serverResponse.consolidatedErrorMessage);
            } else{
              this.snack.showSuccessSnack("Case cancelled!");
              this.editCustomerFacingStatusDialog(miCase, statusTrackStatus);
            }
          });
        } else if (statusTrackStatus === "IN-PROGRESS") {        
          this.caseService.inProgressMICase(miCase.id).subscribe((serverResponse) => {
            if (serverResponse.hasErrors) {
              this.snack.showErrorSnack("Error setting case to IN PROGRESS: " + serverResponse.consolidatedErrorMessage);
            } else{
              this.snack.showSuccessSnack("Case set to IN PROGRESS!");
              this.editCustomerFacingStatusDialog(miCase, statusTrackStatus);
            }
          });
        }
      }
    });
  }

  editCustomerFacingStatusDialog(miCase: any, statusTrackStatus: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCase: miCase,
      infoLine1: "Case: " + miCase.id + " Main Status: " + statusTrackStatus,
      statusTrackDomain: "MI_CASE",
      statusTrackElement: "MI_CASE_CF_STATUS",
      elementLabel: "Customer Facing Status",
      initialStatusTrackStatus: miCase.customerFacingStatusCode
    }
    const dialogRef = this.matDialog.open(StatusTrackManualAssignComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getCases();
      }
    });
  }

  openCommentDialog = (miCaseId:number) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: "MI_CASE",
      relatedId: miCaseId
    }
    const dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
  }

  getMICaseMainStatusTrackStatuses = () => {
    this.performXFRequest({
      requestDescription: "Get Status Track Statuses",
      requestFn: this.caseService.getMICaseMainStatusTrackStatuses,
      fnParams: [],
      onSuccess: (data) => {
        this.miCaseMainStatusTrackStatuses = data;
      },
      onError: (err) => {
        super.showWarningSnack("Unable to retrieve filter options for Statuses");
        this.log.error(err);
      }
    });
  }

  getCustomerFacingStatuses = () => {
    this.statusTrackService.getManualStatusTrackStatusListForElement(
      StatusTrackService.ELEMENT_MI_CASE_CF_STATUS).subscribe((serverResponse) => {
    if (serverResponse.hasErrors) {
      super.showErrorSnack(serverResponse.consolidatedErrorMessage);
    } else{
      this.customerFacingStatuses = serverResponse.data;
    }
  });
  }

  toggleIdOrderBy = () => {
    if(this.idOrderBy === "ASC" || this.idOrderBy == null) {
      this.idOrderBy = "DESC";
    }else if(this.idOrderBy === "DESC") {
      this.idOrderBy = "ASC"
    }
    this.dueDateOrderBy = null;
    this.getCases();
  }

  toggleDueDateOrderBy = () => {
    if(this.dueDateOrderBy === "ASC" || this.dueDateOrderBy == null) {
      this.dueDateOrderBy = "DESC";
    }else if(this.dueDateOrderBy === "DESC") {
      this.dueDateOrderBy = "ASC"
    }
    this.idOrderBy = null;
    this.getCases();
  }

  createManualTask(miCaseId: number) {
    if (miCaseId == null || this.taskTypes == null) {
      super.showErrorSnack("Data required for manual task creation is not present. Please contact system support.")
      return;
    }
    // show create task dialog
    const config = new MatDialogConfig();
    config.data = {
      miCaseId: miCaseId,
      taskTypes: this.getManualEntryTaskTypes(),
      taskStatuses: this.taskStatuses
    };

    const dialog = this.matDialog.open(XfwfCreateManualComponent, config);
    dialog.afterClosed().subscribe((data) => {
      this.getCases(); // refresh
    });
  }

  getManualEntryTaskTypes(): any[] {
    if (this.taskTypes == null) {
      return [];
    }
    return this.taskTypes.filter((type: any) => type.properties.allowManualEntry === true);
  }

  openEmailDialog = () => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    const dialogRef = this.matDialog.open(SendEmailDialogComponent, matDialogConfig);
  }
}
