<lib-page #page [showTitle]="false">

  <ng-container pageTitle>
    Finance
  </ng-container>

  <ng-container pageContent class="container-override">
    <form [formGroup]="formGroup" class="alt-theme alt-edit-form">
      <mat-checkbox formControlName="claimPaid">Claim Paid</mat-checkbox>

      <mat-form-field>
        <mat-label>Client Internal Adjustments</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="clientInternalAdjustments"
               (wheel)="$event.preventDefault()">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Pre 6DH Review Payable</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="preReviewPayable">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Post Review Payable</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="postReviewPayable">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Post Appeal Payable</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="postAppealPayable">
      </mat-form-field>

      <lib-date-input
        [reactive]="true"
        [formGroup]="formGroup"
        formControlName="drgPaidDate"
        name="drgPaidDate"
        label="DRG Paid Date"
      >
      </lib-date-input>

      <mat-form-field>
        <mat-label>DRG Payment Amount</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="drgPaymentAmount">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Network Discount</mat-label>
        <mat-icon matSuffix>percent</mat-icon>
        <input class="input" type="number" matInput formControlName="networkDiscount">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Outlier Amount</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="outlierAmount">
      </mat-form-field>

      <lib-date-input
        [reactive]="true"
        [formGroup]="formGroup"
        formControlName="outlierPaidDate"
        name="outlierPaidDate"
        label="Outlier Paid Date"
      >
      </lib-date-input>

      <mat-form-field>
        <mat-label>Invoice Amount</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="invoiceAmount">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Addl Invoice Amount</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="additionalInvoiceAmount">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Addl Invoice Number</mat-label>
        <input matInput formControlName="additionalInvoiceNumber">
      </mat-form-field>
      <lib-date-input
        [reactive]="true"
        [formGroup]="formGroup"
        formControlName="additionalInvoiceDate"
        name="additionalInvoiceDate"
        label="Addl Invoice Date"
      >
      </lib-date-input>
      <mat-form-field>
        <mat-label>Addl Allowed Amount (Level 1)</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="additionalAllowedAmountLevel1">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Addl Allowed Amount (Level 2)</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="additionalAllowedAmountLevel2">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Addl Allowed Amount (Level 3)</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="input" type="number" matInput formControlName="additionalAllowedAmountLevel3">
      </mat-form-field>
    </form>

    <ng-container *ngIf="miCaseFinanceInfo.miCaseDbrdSavingsStatus != 'READY'">
      Savings Dashboard:
      <button mat-flat-button color="accent" (click)="setDbrdSavingsReady()">Not Ready</button>
    </ng-container>
    <ng-container *ngIf="miCaseFinanceInfo.miCaseDbrdSavingsStatus == 'READY'">
      Savings Dashboard:
      <button mat-flat-button color="accent" (click)="setDbrdSavingsNotReady()"
              class="mi-case-finance-dbrd-savings-ready">Ready
      </button>
    </ng-container>
  </ng-container>
  <ng-container pageActions class="container-override">
    <button style="align-self: flex-end;" mat-flat-button color="accent" (click)="save()"> Save</button>
  </ng-container>
</lib-page>
