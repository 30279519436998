<div matDialogTitle>
  <h2>Send Email</h2>
</div>

<mat-dialog-content class="alt-theme">
  <form [formGroup]="formGroup" class="form">
    <mat-form-field appearance="outline">
      <mat-label>Addressee Name</mat-label>
      <input matInput formControlName="toName">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="toAddress" type="email">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Subject Line</mat-label>
      <input matInput formControlName="subject">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message Body</mat-label>
      <textarea matInput formControlName="messageBody" rows="5" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </form>

</mat-dialog-content>


<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="sendEmail()"
    [disabled]="!formGroup.valid"
  >
    <mat-icon>email</mat-icon>
    Send Email
  </button>

  <button
    mat-stroked-button
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
    Close
  </button>
</mat-dialog-actions>