import { Deserializable } from "../deserializable";


export class MICaseFinanceUpdatePacket extends Deserializable<MICaseFinanceUpdatePacket> {
  id: number;
  miCaseId: number = -1;
  claimPaid: boolean = false;
  clientInternalAdjustments: number;
  preReviewPayable: number;
  postReviewPayable: number;
  postAppealPayable: number;
  drgPaidDate: string;
  drgPaymentAmount: number;
  networkDiscount: number;
  outlierAmount: number;
  outlierPaidDate: string;
  invoiceAmount: number;
  additionalInvoiceAmount: number;
  additionalInvoiceNumber: string;
  additionalInvoiceDate: string;
  additionalAllowedAmountLevel1: number;
  additionalAllowedAmountLevel2: number;
  additionalAllowedAmountLevel3: number;
}
