<div class="wrapper">
  <div class="header">
    <h2>{{getHeaderText()}}</h2>
    <button
      mat-icon-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>


  <form [formGroup]="formGroup">
    <div [ngClass]="['form-wrapper', 'alt-theme']">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Dev Code</mat-label>
        <input matInput placeholder="Dev Code" formControlName="apeRuleFilterDevCode">
      </mat-form-field>
      
      <mat-form-field appearance="outline">
        <mat-label>Filter Domain</mat-label>
        <mat-select formControlName="apeRuleFilterDomain">
          <mat-option *ngFor="let domain of domains" [value]="domain">{{domain}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput cdkTextareaAutosize rows="4" placeholder="Description" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    
  </form>

  <div [ngClass]="['alt-theme', 'btn-group']">
    <button
      mat-stroked-button
      color="accent"
      (click)="submit()"
      [disabled]="!formGroup.valid"
      style="margin-right: 3px"
    >
      <mat-icon>save</mat-icon> Save
    </button>

    <button
      mat-stroked-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>cancel</mat-icon> Cancel
    </button>
  </div>
</div>