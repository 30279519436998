import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  Logger
} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseService {
  baseEndpoint = this.apiEndpoint("email");

  constructor(
    protected override httpClient: HttpClient
  ) {
    super(new Logger("EmailService"));
  }

  sendEmail = (dto): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}/send`, dto);
  }
}
