<lib-page>
  <ng-container pageTitle>
    <span> Medical Incident Stays </span>
    <button class="add" mat-flat-button (click)="createCase()" data-action="create-case">
      <mat-icon>add_circle</mat-icon>
      New
    </button>
  </ng-container>
  <ng-container pageContent>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>Filters</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field *ngIf="!miCaseId" class="search-field" appearance="outline" color="accent">
          <mat-label>
            Stay ID
          </mat-label>
          <input type="number" matInput [(ngModel)]="queryParams.miCaseId" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            <!-- Alt External Id -->
            Client Claim ID
          </mat-label>
          <input matInput [(ngModel)]="queryParams.clientClaimId" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Case Name
          </mat-label>
          <input matInput [(ngModel)]="queryParams.miCaseName" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Status
          </mat-label>
          <mat-select id="statusTrackFilter" [(ngModel)]="queryParams.statusTrackStatus" (valueChange)="searchMICases()">
            <mat-option *ngFor="let statusTrackStatus of miCaseMainStatusTrackStatuses" [value]="statusTrackStatus.value">
              {{statusTrackStatus.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            CF Status
          </mat-label>
          <mat-select id="cfStatusFilter" [(ngModel)]="queryParams.customerFacingStatus" (valueChange)="searchMICases()">
            <mat-option *ngFor="let cfStatus of customerFacingStatuses" [value]="cfStatus.statusTrackStatus">
              {{cfStatus.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Created By
          </mat-label>
          <input matInput [(ngModel)]="queryParams.createdBy" (keydown.enter)="searchMICases()">
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Primary Client Name
          </mat-label>
          <input matInput [(ngModel)]="queryParams.primaryClientName" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Plan Name
          </mat-label>
          <input matInput [(ngModel)]="queryParams.healthCarePlanName" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>

        <div class="button-group">
          <button mat-flat-button color="accent" class="search-button" (click)="searchMICases()">
            <mat-icon>search</mat-icon>
            Search
          </button>
          <button mat-stroked-button color="accent" (click)="clearSearch()">
            Clear
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-table [dataSource]="dataSource">

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell (click)="toggleIdOrderBy()" *matHeaderCellDef style="cursor: pointer;">
          <mat-icon>{{idOrderBy === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon>
          Stay Id
        </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <button mat-button mat-raised-button 
            [color]="!case.terminalStatus ? 'accent' : 'warn'" class="mi-case-btn"
            [matTooltip]="case.terminalStatus ? case.statusTrackStatus : ''"
            (click)="navigateToCase(case.id)"
            [attr.data-case-name]="case.name">
            <mat-icon matListIcon>folder</mat-icon> {{case.id}}
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caseType">
        <mat-header-cell *matHeaderCellDef> Case Type / LoB </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <table>
            <tr>
              <td style="font-weight: 300;">{{case.type}}</td>
            </tr>
            <tr>
              <td style="font-size: small; font-weight: 500;">{{case.lineOfBusiness}}</td>
            </tr>
          </table>  
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusTrackStatus">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let case">

        </mat-cell>
      </ng-container>

      <!-- Alt External ID Column (labeled client claim id) -->
      <ng-container matColumnDef="altexternalid">
        <mat-header-cell *matHeaderCellDef> Client Claim ID / DoS</mat-header-cell>
        <mat-cell *matCellDef="let case"> 
          <table>
            <tr>
              <td><app-truncate-text>{{case.clientClaimId}}</app-truncate-text></td>
            </tr>
            <tr>
              <td style="font-size: small; font-weight: 300;">{{case.coverPeriodFromDate}} - {{case.coverPeriodThruDate}}</td>
            </tr>
            <!-- If the case is on hold and we have a On Hold Review Date (i.e. dueDate) display it here -->
            <tr *ngIf="case?.onHold">
              <td><span style="font-size: small; color: darkred">Due: {{case.dueDate | date: 'MM/dd/yyyy' }}</span></td>
            </tr>            
          </table>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Case Name </mat-header-cell>
        <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.name}}</app-truncate-text> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="patientName">
        <mat-header-cell *matHeaderCellDef> Patient Name </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <ng-template #notAvailable>
            <app-truncate-text class="patient-name-not-available">{{case.patientLastName}}</app-truncate-text>
          </ng-template>
          <app-truncate-text *ngIf="case.patientLastName != 'Not Available'; else notAvailable"></app-truncate-text>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="currentXFWFStageName">
        <mat-header-cell *matHeaderCellDef> WF Stage / Status </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <ng-container *ngIf="case?.statusTrackStatusCode === 'CREATED_UNASSIGNED' || case?.statusTrackStatusCode === 'IN_PROGRESS_UNASSIGNED' || case?.statusTrackStatusCode === 'AWAITING_NEXT_TASK_CREATE'; else noHyperlink">
            <table>
              <tr>
                <td>{{case.currentXFWFStageName}}</td>
              </tr>
              <tr>
                <td>
                    <a style="color: blue" [routerLink]="" (click)="navigateToMiCaseTaskList(case)">{{case.statusTrackStatus}}</a>
                </td>
              </tr>
              <tr>
                <td style="font-size: small;">{{case.customerFacingStatus}}</td>
              </tr>
            </table>
            <app-truncate-text>

            </app-truncate-text>
          </ng-container>
          <ng-template #noHyperlink>
            <table>
              <tr>
                <td>
                  <span>
                    {{case.statusTrackStatus}}
                  </span>
                </td>
              </tr>
              <tr>
                <td style="font-size: small; font-weight: 300;">{{case.customerFacingStatus}}</td>
              </tr>
              <tr>
                <td style="word-wrap: break-word;">
                  <span *ngIf="case.onHold" style="color: darkred">
                    {{case.holdReasonUserLabel || 'Unspecified'}}
                  </span>
                </td>                
              </tr>
            </table>
          </ng-template>          
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
        <mat-cell *matCellDef="let case">
            <table>
              <tr>
                <td style="font-size: small;">{{case.createdUser}}</td>
              </tr>
              <tr>
                <td style="font-size: small;">{{case.createdDate | date:'MM/dd/yyyy'}}</td>
              </tr>
            </table>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.createdDate | date:'MM/dd/yyyy'}}</app-truncate-text> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <table>
            <tr>
              <td>{{case.clientName}}</td>
            </tr>
            <tr>
              <td style="font-size: small; font-weight: 300;">{{case.healthCarePlanName}}</td>
            </tr>
          </table> 
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef> Assignee </mat-header-cell>
        <mat-cell *matCellDef="let case">{{case.assignee}}</mat-cell>
      </ng-container>

      <!-- Retired.
      <ng-container matColumnDef="lineOfBusiness">
        <mat-header-cell *matHeaderCellDef> Line Of Business </mat-header-cell>
        <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.lineOfBusiness}}</app-truncate-text> </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef (click)="toggleDueDateOrderBy()" style="cursor: pointer;">
          <mat-icon>{{dueDateOrderBy === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon>
          Due Date
        </mat-header-cell>
        <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.caseReviewDueDate | date:'MM/dd/yyyy'}}</app-truncate-text> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <button
            mat-icon-button
            [matMenuTriggerFor]="actionMenu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #actionMenu="matMenu">
            <!-- <button
              mat-menu-item
              (click)="openCommentDialog(case.id)"
            >
              <mat-icon>comment</mat-icon> View Comments
            </button> -->

            <app-comment-list-dialog-button
              buttonType="menuItem"
              [relatedId]="case.id"
              commentMode="MI_CASE"
              [externalCommentCount]="case.commentCount"
            ></app-comment-list-dialog-button>

            <!-- Actions here are only available if the case is not in 'terminalStatus'-->
            <ng-container *ngIf="!case.terminalStatus">
              <button
                *ngIf="case.statusTrackStatus !== 'COMPLETE' && !case.onHold"
                mat-menu-item
                (click)="confirmStatusActionDialog(case, 'COMPLETE')"
              >
                <mat-icon>task</mat-icon> Close/Complete
              </button>

              <button
                *ngIf="case.statusTrackStatus !== 'CANCELLED' && !case.onHold"
                mat-menu-item
                (click)="confirmStatusActionDialog(case, 'CANCELLED')"
              >
                <mat-icon>task</mat-icon> Cancel
              </button>

              <button mat-menu-item (click)="createManualTask(case.id)" *ngIf="showCreateManualXFWFTaskButton">
                <mat-icon>add_circle</mat-icon>
                Create Manual XFWF Task
              </button>

              <button mat-menu-item (click)="openEmailDialog()" *ngIf="admin">
                <mat-icon>email</mat-icon>
                Email
              </button>
            </ng-container>

            <!-- Actions here are only available if the case is in 'terminalStatus' (ADMINs only)-->
            <ng-container *ngIf="case.terminalStatus && canReOpen">
              <button
                *ngIf="!case.onHold"
                mat-menu-item
                (click)="confirmStatusActionDialog(case, 'IN-PROGRESS')"
              >
                <mat-icon>task</mat-icon> Re-open Case (IN PROGRESS)
              </button>              
            </ng-container>           

          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayColumns;"
        [class.on-hold]="row?.onHold"
        [class.terminal-status]="row?.terminalStatus"
        [class.deadline-approaching]="row?.deadlineApproaching"
        [class.striped-background]="row?.duplicateParentCaseId != null"
        [class.stalled]="row?.statusTrackStatusCode === 'CREATED_UNASSIGNED' || row?.statusTrackStatusCode === 'IN_PROGRESS_UNASSIGNED' || row?.statusTrackStatusCode === 'AWAITING_NEXT_TASK_CREATE'"
      >
      </mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>
